import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import addIconGrey from '../../assets/images/icon-add-grey.svg';
import iconNotStarted from '../../assets/images/icon-campaign-not-started.svg';
import iconCompleted from '../../assets/images/icon-campaign-completed.svg';
import iconClosed from '../../assets/images/icon-campaign-closed.svg';
import iconInProgress from '../../assets/images/icon-campaign-in-progress.svg';
import {
    Button,
    Text,
    Column,
    AlignItems,
    SpaceBetween
} from '../../components';
import JustifyContentCenter from '../../components/base/Layout/JustifyContentCenter';
import Router from '../../routes/router';
import { User } from '../../context/reducer';
import { Campaign } from './types';

type Props = {
    statusData: Campaign[];
    user: User;
};

const CampaignStatus: React.FC<Props> = ({ statusData, user }) => {
    const [counter, setCounter] = useState({
        notStarted: 0,
        inProgress: 0,
        completed: 0,
        ClosedInComplete:0
    });

    const statusResolver = (data: Campaign[]) => {
        const progressCounter = {
            notStarted: 0,
            inProgress: 0,
            completed: 0,
            ClosedInComplete:0
        };
        data &&
            data.forEach((el: Campaign) => {
                if (el.closedAt && el.progress > 0) {
                    progressCounter.completed++; //closed_complete
                } else if (
                    el.closedAt &&
                    (el.progress > 0 || el.progress <= 99)
                ) {
                    progressCounter.ClosedInComplete++;
                } else if (el.progress === 0) {
                    progressCounter.notStarted++;
                } else {
                    progressCounter.inProgress++;
                }
            });
        return progressCounter;
    };

    useEffect(() => {
        setCounter(statusResolver(statusData));
    }, [statusData]);
    return (
        <Column style={{ marginTop: 40 }}>
            <SpaceBetween style={{ marginBottom: 8 }}>
                <CampaignStatusHeading>
                    Your campaign status
                </CampaignStatusHeading>
                {user && (user.role && user.countryUsers&&user.countryUsers.some(cu=>cu.campaignCreateEnabled)) && (
                    <CreateCampaignButton
                        onClick={() => Router.goToEvaluateCreateCampaign()}
                    >
                        <img src={addIconGrey} alt="add" aria-hidden={true} />
                        <AddIconText id="skip-to-content" tabIndex={-1}>Create campaign</AddIconText>
                    </CreateCampaignButton>
                )}
            </SpaceBetween>
            <CampaignStatusWrapper>
                <Column style={{ width: '100%' }}>
                    <AlignItems style={{ justifyContent: 'space-around' }}>
                        <SpaceBetween style={{ width: '100%' }}>
                            <AlignItems>
                                <img
                                    src={iconNotStarted}
                                    alt=""
                                    style={{ marginRight: 24 }}
                                />
                                <Column>
                                    <CampaignStatusAmount>
                                        {`${counter.notStarted}/${(statusData &&
                                            statusData.length) ||
                                            0}`}
                                    </CampaignStatusAmount>
                                    <CampaignStatusDescription style={{ marginRight: '7vw' }}>
                                        Campaigns have not started
                                    </CampaignStatusDescription>
                                </Column>
                            </AlignItems>
                            <AlignItems >
                                <img
                                    src={iconInProgress}
                                    alt=""
                                    style={{ marginRight: 24 }}
                                />
                                <Column>
                                    <CampaignStatusAmount>
                                        {`${counter.inProgress}/${(statusData &&
                                            statusData.length) ||
                                            0}`}
                                    </CampaignStatusAmount>
                                    <CampaignStatusDescription style={{ marginRight: '7vw' }}>
                                        Campaigns are in progress
                                    </CampaignStatusDescription>
                                </Column>
                            </AlignItems>

                            <AlignItems >
                                <img
                                    src={iconClosed}
                                    alt=""
                                    style={{ marginRight: 24 }}
                                />
                                <Column>
                                    <CampaignStatusAmount>
                                        {`${counter.ClosedInComplete}/${(statusData &&
                                            statusData.length) ||
                                            0}`}
                                    </CampaignStatusAmount>
                                    <CampaignStatusDescription style={{ marginRight: '7vw' }}>
                                        Campaigns are incomplete and closed
                                    </CampaignStatusDescription>
                                </Column>
                            </AlignItems>
                            <AlignItems>
                                <img
                                    src={iconCompleted}
                                    alt=""
                                    style={{ marginRight: 24 }}
                                />
                                <Column>
                                    <CampaignStatusAmount>
                                        {`${counter.completed}/${(statusData &&
                                            statusData.length) ||
                                            0}`}
                                    </CampaignStatusAmount>
                                    <CampaignStatusDescription style={{ marginRight: '7vw' }} >
                                        Campaigns are completed and closed
                                    </CampaignStatusDescription>
                                </Column>
                            </AlignItems>
                        </SpaceBetween>
                    </AlignItems>
                </Column>
            </CampaignStatusWrapper>
        </Column>
    );
};

const CampaignStatusWrapper = styled(JustifyContentCenter)`
    background: #fff;
    margin-bottom: 50px;
    font-size: 19px;
    text-align: center;
    padding: 32px 56px;
    box-shadow: 0 5px 5px #f5f5f5;
    border: 1px solid #d9d9d9;
`;

const CampaignStatusHeading = styled(Text)`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #575757;
    display: flex;
    align-items: center;
`;

const AddIconText = styled(Text)`
    font-size: 16px;
    font-weight: 500;
    color: #4f4f4f;
    margin-left: 8px;
`;

const CampaignStatusAmount = styled(Text)`
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #4f4f4f;
    text-align: initial;
`;

const CampaignStatusDescription = styled(Text)`
    font-size: 16px;
    color: #4f4f4f;
`;

const CreateCampaignButton = styled(Button)`
    && {
        padding: 13px 16px;
        border: solid 1.5px #d9d9d9;
        background-color: #fff;
    }
`;

export default CampaignStatus;
